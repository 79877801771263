import classNames from 'classnames';
import logo from '../../icons/vt_landscape.svg';
import { EditIcon, CopyIcon } from '../../icons';
import { Status } from '../../types';
import styles from './styles.module.css';
import { ReactNode } from 'react';


interface ErrorMsgProps {
  message: string
}

interface LoaderProps {
  text?: string
}

interface IButton {
  onClick: () => void
}

interface ButtonProps extends IButton {
  text: string,
  loading?: boolean,
  disabled?: boolean,
  type?: Status
}

interface ArrowButtonProps extends IButton {
  type: 'left' | 'right'
}

export const ArrowLeft = () => (
  <div className={classNames(styles.Arrow, styles.left)} />
);

export const ArrowRight = () => (
  <div className={styles.Arrow} />
);

export const ArrowDown = () => (
  <div className={classNames(styles.Arrow, styles.down)} />
);

export const ArrowUp = () => (
  <div className={classNames(styles.Arrow, styles.up)} />
);

export const Centered = ({ children }: {children: ReactNode}) => (
  <div className={styles.Centered}>{ children }</div>
);

export const Spinner = () => (
  <div className={styles.Spinner} />
);

export const ButtonSpinner = () => (
  <div className={styles.buttonSpinner} />
);

interface SpinnerOverlayProps {
  children: ReactNode,
  cover: boolean,
}

export const SpinnerOverlay: React.FC<SpinnerOverlayProps> = ({children, cover}) => (
  <div className={styles.SpinnerOverlay}>
    {children}
    {cover && (<><div className={styles.overlayShadow} /><div className={styles.overlayCenter}><Spinner /></div></>)}
  </div>
);

export const PageLoader = ({ text }: LoaderProps) => (
  <div className={styles.PageLoader}>
    <Centered>
      <Spinner />
      <>
        { text ? <p>{text}</p> : null }
      </>
    </Centered>
  </div>
)

export const Loader = ({ text }: LoaderProps) => (
  <Centered>
    <Spinner />
    <>
      { text ? <p>{text}</p> : null }
    </>
  </Centered>
)

export const PoweredBy = () => {
  return (
    <div className={styles.PoweredBy}>
      <p>Powered by</p>
      <img src={logo} alt="Viimatech - Motor Is Sensor" />
    </div>
  );
}

export const ErrorMsg = ({ message }: ErrorMsgProps): JSX.Element => {
  return (
    <p className={styles.danger}>{message}</p>
  )
}

export const AddButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const {children, ...propsWithoutChildren} = props;

  return (
    <button className={styles.add} {...propsWithoutChildren}>
      +
      { children }
    </button>
  );
};

export const RemoveButton = ({ onClick }: IButton) => {
  return (
    <button className={styles.remove} onClick={onClick}>
      X
    </button>
  )
}

export const CopyButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const {children, ...propsWithoutChildren} = props;
  return (
    <button className={styles.copy} {...propsWithoutChildren}>
      <CopyIcon />
      { children }
    </button>
  );
};

export const EditButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const {children, ...propsWithoutChildren} = props;
  return (
    <button className={styles.copy} {...propsWithoutChildren} >
      <EditIcon />
      { children }
    </button>
  );
};

export const WaterDropButton = ({ onClick }: IButton) => {
  return (
    <button className={styles.waterDrop} onClick={onClick} />
  )
}

export const Button = ({ onClick, text, loading, disabled, type='OK' }: ButtonProps) => {
  return (
    <button className={classNames(styles.regular, { disabled: loading || disabled }, statusMap[type].className)} onClick={onClick} disabled={loading || disabled} >
      { text } { loading && <ButtonSpinner /> }
    </button>
  )
}

export const ArrowButton = ({ onClick, type }: ArrowButtonProps) => {
  return (
    <button className={classNames(styles.arrow, styles.right)} onClick={onClick}>
      { type === 'left' ? <ArrowLeft /> : <ArrowRight /> }
    </button>
  )
}

export const statusMap: Record<Status, { className: string, text: string, color: string }> = {
  'OK': {className: styles['status-good'], text: 'ok', color: '#93C152'},
  'WARNING': {className: styles['status-warning'], text: '!', color: '#EFB600'},
  'DANGER': {className: styles['status-danger'], text: '!', color: '#E52000'},
};