import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ChoiceWidget from '../ChoiceWidget';
import { ArrowLeft, ArrowRight } from '../Sugar';
import styles from './styles.module.css';

interface PaginationProps {
  page: number,
  pageCount: number,
  handlePageChange: (page: number) => void
}

interface TableConfig {
  totalEntries: number,
  page: number,
  rowsLength: number
}

interface OrderConfig {
  name: string,
  choices: {value: string, title: string}[],
  order: string,
  onChange: (event: React.FormEvent<HTMLInputElement>) => void
}

interface ConfigProps {
  tableConfig?: TableConfig,
  orderConfig: OrderConfig
}

interface TableProps {
  children?: React.ReactNode,
  noFirstBorder?: boolean,
}

export const Pagination = ({ page, pageCount, handlePageChange }: PaginationProps) => {
  let buttons: JSX.Element[] = [];
  if (pageCount <= 5) {
    for (let i = 1; i <= pageCount; i++) {
      buttons.push(<button className={page === i ? styles.active : ''} key={i} onClick={() => handlePageChange(i)}>{i}</button>);
    }
  } 
  else {
    if (page <= 2) {
      for (let i = 1; i <= 3; i++) {
        buttons.push(<button className={page === i ? styles.active : ''} key={i} onClick={() => handlePageChange(i)}>{i}</button>)
      }
      buttons = [
        ...buttons,
        <button key={4} className={styles.separator}>...</button>,
        <button key={pageCount} onClick={() => handlePageChange(pageCount)}>{pageCount}</button>
      ];
    } else if (page >= pageCount-1) {
      buttons = [
        <button key={1} onClick={() => handlePageChange(1)}>{1}</button>,
        <button key={2} className={styles.separator}>...</button>
      ];
      for (let i = pageCount-2; i <= pageCount; i++) {
        buttons.push(<button className={page === i ? styles.active : ''} key={i} onClick={() => handlePageChange(i)}>{i}</button>);
      }
    } else {
      buttons = [
        <button key={1} onClick={() => handlePageChange(1)}>{1}</button>,
        <button key={2} className={styles.separator}>...</button>,
        <button key={3} onClick={() => handlePageChange(page-1)}>{page-1}</button>,
        <button key={4} className={styles.active}>{page}</button>,
        <button key={5} onClick={() => handlePageChange(page+1)}>{page+1}</button>,
        <button key={6} className={styles.separator}>...</button>,
        <button key={7} onClick={() => handlePageChange(pageCount)}>{pageCount}</button>
      ]
    }
  }
  return (
      <div className={styles.pagination}>
      { <button className={styles.arrow} disabled={page === 1} onClick={() => handlePageChange(page-1)}><ArrowLeft /></button> }
      { buttons }
      { <button className={styles.arrow} disabled={page === pageCount} onClick={() => handlePageChange(page+1)}><ArrowRight /></button> }
    </div>

  )
}

export const Config = ({ tableConfig, orderConfig }: ConfigProps) => {
  const { t } = useTranslation();
  const { name, choices, order, onChange } = orderConfig;

  return (
    <div className={styles.tableConfig}>
      <fieldset>
        <legend>{t('order_by')}</legend>
        <ChoiceWidget
          name={name}
          choices={choices}
          selected={order}
          onInput={onChange}
        />
      </fieldset>
      <div className={styles.entryCount}>
        {tableConfig && (
          t('table_entry_count', {
            first: (tableConfig.page - 1) * 20 + 1,
            last: (tableConfig.page - 1) * 20 + tableConfig.rowsLength,
            total: tableConfig.totalEntries
          })
        )}
      </div>
    </div>
  );
};

const Table = ({ children, noFirstBorder }: TableProps): JSX.Element => {
  const borderStyles = noFirstBorder ? styles.noFirstBorder : '';

  return (
    <table className={classNames(styles.table, borderStyles) } >
      { children }
    </table>
  );
}

export default Table;
