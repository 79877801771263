/* Helper functions.
*/

import i18next from "i18next";
import i18n from "./i18n";
import { AuthUser, IAlert } from "./types";

export const formatDateTime = (d: Date) => {
  const year = d.getFullYear();
  const month = ('' + (d.getMonth() + 1)).padStart(2, '0');
  const day = ('' + d.getDate()).padStart(2, '0');
  const hour = ('' + d.getHours()).padStart(2, '0');
  const minute = ('' + d.getMinutes()).padStart(2, '0');
  const second = ('' + d.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export const formatLocaleDateTime = (d: Date) => {
  const date = new Date(d);
  const dtf = new Intl.DateTimeFormat(getLanguage(), 
    { 
      dateStyle: 'medium',
      timeStyle: 'medium',
      hour12: false
  });

  return dtf.format(date);
}

export const durationBetweenDates = (d1: Date, d2: Date) => {
  const diffTime = Math.abs(new Date(d1).valueOf() - new Date(d2).valueOf());
  const days = diffTime / (24*60*60*1000);
  const hours = (days % 1) * 24;
  const minutes = (hours % 1) * 60;
  const seconds = Math.round((minutes % 1) * 60);
  const valueLabel: [number, string][] = [
    [days, 'day'],
    [hours, 'hour'],
    [minutes, 'minute'],
    [seconds, 'second']
  ];
  let result = '';
  for (const [val, label] of valueLabel) {
    if (val >= 1) {
      const value = Math.floor(val);
      result += `${result.length > 0 ? ', ' : ''} ${value} ${i18n.t(label, { count: value })}`;
    }
  }

  return result;
}

export const timeSince = (d: Date) => {
  const now: Date = new Date();
  const seconds = Math.floor((now.getTime() - d.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  if (minutes >= 15) {
    return `>${i18n.t('minutes_ago', { count: 15 })}`;
  } else if (minutes >= 1) {
    return `${i18n.t('circa_abbr')} ${i18n.t('minutes_ago', { count: minutes })}`; 
  }
  return i18n.t('seconds_ago', { count: seconds });
}

export const createNoticeMarker = (notice: IAlert) => {
  const hoursInMs = 432e5;
  const startTime = formatDateTime(new Date(new Date(notice.startTime).getTime() - hoursInMs));
  const diff = new Date().getTime() - new Date(notice.startTime).getTime();
  let endTime: string = 'now';
  if (diff >= hoursInMs) {
    endTime = formatDateTime(new Date(new Date(notice.startTime).getTime() + hoursInMs));
  }
  
  return {
    timerangeStart: startTime,
    timerangeEnd: endTime,
    noticeTitle: notice.forSeriesName,
    noticeStart: formatDateTime(new Date(notice.startTime))
  }
}

export const requestedTimeFormat = (tStr: string) => (
  // If the time range is relative (including "now"), pass them as is,
  // otherwise assume datetime strings and try to convert them to utc.
  tStr.includes('now') ? tStr : new Date(tStr).toISOString().replace(/\.000Z$/,'Z')
  // TODO: Validation / error checking :-)
);

export const convertSecondsToHMS = (sec: number) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - (hours * 3600)) / 60);
  const seconds = Math.round(sec - (hours * 3600) - (minutes * 60));
  const valueLabel: [number, string][] = [
    [hours, 'hour'],
    [minutes, 'minute'],
    [seconds, 'second']
  ];
  let result = '';
  for (const [value, label] of valueLabel) {
    if (value >= 1) {
      result += `${result.length > 0 ? ', ' : ''} ${value} ${i18n.t(label, { count: value })}`;
    }
  }

  if (result === '') {
    result = `0 ${i18n.t('second', { count: 0 })}`
  }

  return result;
}

export const hasEditPermissionForOrg = (orgId: string, user?: AuthUser | null) => {
  const result = user && user.memberships.find(m => m.publicId === orgId && m.role === 'edit');
  return result ? true : false;
}

export const getLanguage = (): string => i18next.language || window.localStorage.i18nextLng;
