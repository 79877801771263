import { ReactNode, useEffect, useMemo, useState } from "react";
import { convertSecondsToHMS, createNoticeMarker, formatLocaleDateTime, hasEditPermissionForOrg, requestedTimeFormat } from "../../util";
import { AddButton, Centered, EditButton, ErrorMsg, Loader, Button, WaterDropButton } from "../Sugar";
import Breadcrumbs, { BreadcrumbLink } from "../Breadcrumbs";
import SectionBox from '../SectionBox';
import LeafletMap, { IMarker } from '../Map';
import styles from './styles.module.css'
import API, { ApiEquipmentWithSeries, ApiFleet, ApiStation } from "../../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "../Table";
import Title from "../Title";
import ArrowButton from "../Button";
import { ChartData, IAlert, INoticeMarker, IPump, PlotData, Serie, Status } from "../../types";
import Plot, { plotColors } from "../Plot"
import { useAlerts, useAuth } from "../../hooks";
import RecentAlerts from "../RecentAlerts";
import { useTranslation } from "react-i18next";
import { mostSevereAlertStatus, stateToStatus } from "../Alerts";

interface OverviewTableProps {
  pumps: Partial<IPump>[],
  deviceInstallations: DeviceInstallation[],
  tankEquipment: ApiEquipmentWithSeries | null,
  fleetInfo: any
}

interface DeviceInstallation {
  installTime: Date,
  serial: string,
  typeCode: string,
  uninstallTime: Date | null
}

interface StationChartProps {
  series: Serie[],
  timeRange: string[],
  setTimeRange: (timeRange: string[]) => void,
  noticeMarker: INoticeMarker | null,
  removeNoticeMarker: () => void
}

interface IntermediateChartProps {
  series: Serie[],
  timeRange: string[],
  setTimeRange: (timeRange: string[]) => void,
  type: string
  title: string
  unit: string
}

interface IntermediateTableProps {
  series: Serie[],
  timeRange: string[],
}

interface IntermediateValuesProps {
  series: Serie[],
  timeRange: string[],
  setTimeRange: (timeRange: string[]) => void,
}

export const formatStationPath = (fleets: {name: string, publicId: string}[]): BreadcrumbLink[] => {
  // Given a path of fleets, format them for breadcrumbs use so that the last
  // one is considered a station and the others fleets.
  return fleets.map((fleet, i) => {
    return {
      to: i === fleets.length - 1 ? `/station/${fleet.publicId}` : `/fleet/${fleet.publicId}`,
      title: fleet.name,
    }
  });
};

const OverviewTable = ({ pumps, deviceInstallations, tankEquipment, fleetInfo }: OverviewTableProps): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { alerts } = useAlerts();
  const navigate = useNavigate();

  const editPermission = hasEditPermissionForOrg(fleetInfo.fleet.organizationPublicId, user);
  const stationId: string = fleetInfo.fleet.publicId;

  const overflowAlertSeries = tankEquipment?.series.find((s) => (s.quantity === 'overflow_alert') && s.hasAlertRules) || null;

  return (
    <Table noFirstBorder>
      <tbody>
        {/* Buttons to pump pages */}
        <tr>
          <td></td>
          { pumps.map(pump => {
            // Assume status = 'OK' while alerts are loading. (alerts === null)
            const maybeAlert = alerts?.find(alert => alert.forEquipmentPublicId === pump.publicId);
            const status = maybeAlert ? stateToStatus(maybeAlert.lastState) : 'OK';
            return (
              <td key={pump.publicId}>
                <ArrowButton text={pump.name || t('pump')} bgColor={'light'} status={status}
                  onClick={() => navigate(`/pump/${pump.publicId}`)}
                />
              </td>
            );
          }) }
        </tr>
        <tr>
          <td>{t('equipment_model_name')}</td>
          { pumps.map(pump => (<td key={pump.publicId}>{ pump.modelName || '–' }</td>)) }
          { pumps.length === 0 && <td></td> }
        </tr>
        <tr>
          <td>{t('rated_current')}</td>
          { pumps.map(pump => (<td key={pump.publicId}>{ pump.nominalCurrent ? `${pump.nominalCurrent.toFixed(1)} A` : '–'}</td>)) }
          { pumps.length === 0 && <td></td> }
        </tr>
        <tr>
          <td>{t('rated_power')}</td>
          { pumps.map(pump => (<td key={pump.publicId}>{ pump.nominalPower ? `${pump.nominalPower.toFixed(1)} kW` : '–' }</td>)) }
          { pumps.length === 0 && <td></td> }
        </tr>
        <tr>
          <td>{t('motor_fault_alert')}</td>
          { pumps.map((pump): [string | undefined, ReactNode] => {
            if (pump.motorFaultAlert) {
              return [pump.publicId, t('alerts_enabled')];
            } else if (editPermission) {
              return [pump.publicId, (
                <Button text={t('add_alert')}
                  onClick={() => navigate(`/station/${stationId}/new-motor-alert/${pump.publicId}`)}
                />
              )];
            } else {
              return [pump.publicId, t('alerts_disabled')];
            }
          }).map(([publicId, node]) => (<td key={publicId}>{ node }</td>)) }
          { pumps.length === 0 && <td></td> }
        </tr>
        { /* Overflow alert status/add. */}
        <tr>
          <td>{t('overflow_alert')}</td>
          <td colSpan={Math.max(1, pumps.length)}>
            { overflowAlertSeries ? t('alerts_enabled') : (
              editPermission ? (
                <Button text={t('add_alert')} onClick={() => navigate(`/station/${stationId}/new-overflow-alert `)} />
              ) : t('alerts_disabled')
            ) }
          </td>
        </tr>
        { /* Installed field devices. */ }
        { deviceInstallations.length > 0 && (
          <tr>
            <td>{`VT3 ${t('deployment')}`}</td>
            <td colSpan={Math.max(1, pumps.length)}>
              <div className={styles.vtDeployment}>
                { deviceInstallations.map((item: DeviceInstallation) => {
                  const installTime = formatLocaleDateTime(item.installTime);
                  if (!item.uninstallTime) {
                    return (
                      <div key={item.serial}>
                        <p>{`${t('installed')}: ${installTime}`}</p>
                        <p>
                          <ArrowButton bgColor={'light'} marginTop={true} text={item.serial}
                            onClick={() => navigate(`/devices/${item.serial}`)}
                          />
                        </p>
                      </div>
                    )
                  }
                  return undefined;
                })}
              </div>
            </td>
          </tr>
        ) }
      </tbody>
    </Table>
  );
}

const StationChart = ({ series, timeRange, setTimeRange, noticeMarker, removeNoticeMarker }: StationChartProps): JSX.Element => {
  const { t } = useTranslation();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [chartData, setChartData] = useState<any>(null);
  const [refreshIteration, setRefreshIteration] = useState<number>(0); // TODO: hack :)
  const [maxPhaseCurrent, setMaxPhaseCurrent] = useState<number>(0);

  //Should automatic refreshing be enabled for chart?
  // Yes if the range is relative (e.g. last x// hours).
  const isAutoRefreshOn = timeRange[0].includes('now') || timeRange[1].includes('now');

  useEffect(() => {
    let isMounted = true;
    const pendingRequests = new Map();
    const tmpChartData: { phaseCurrents: ChartData[], surfaceLevel: ChartData | null } = {phaseCurrents: [], surfaceLevel: null};
    let maybeUpdateTimer: ReturnType<typeof setTimeout> | null = null;

    const fetchSeries = (serie: Serie) => {
      const aborter = new AbortController();
      // Save the aborter.
      pendingRequests.set(serie.publicId, aborter);
      const params = new URLSearchParams({
        from: requestedTimeFormat(timeRange[0]),
        to: requestedTimeFormat(timeRange[1])
      }).toString()

      API.getSeries(serie.publicId, params, aborter.signal).then((data: PlotData) => {
        pendingRequests.delete(serie.publicId);
        if (serie.type === "phase_current") {
          tmpChartData.phaseCurrents.push({...serie, data});
          const newMax = Math.max(...data.values);
          if (isMounted && newMax > maxPhaseCurrent) {
            setMaxPhaseCurrent(Math.max(...data.values));
          }
        } else {
          tmpChartData.surfaceLevel = {...serie, data};
        }
        if (isMounted && pendingRequests.size === 0) {
          // Make sure the series are sorted: Pump 1, Pump 2, ...
          tmpChartData.phaseCurrents = tmpChartData.phaseCurrents.sort(
            (a: ChartData, b: ChartData) => a.name.localeCompare(b.name)
          );
          setIsInitialLoading(false);
          setChartData(tmpChartData);

          if (isAutoRefreshOn) {
            maybeUpdateTimer = setTimeout(() => {
              setRefreshIteration(refreshIteration + 1);
            }, 5000);
          }
        }
      }).catch(() => {
        setIsInitialLoading(false);
        setErrorMsg(t('error_history_data'));
        abortAllRequests();
      });
    }

    const abortAllRequests = () => {
      for (const publicId in pendingRequests) {
        pendingRequests.get(publicId).abort();
      }
      pendingRequests.clear();
    };
    if (series.length !== 0) {
      for (const serie of series) {
        fetchSeries(serie);
      }
    } else {
      setErrorMsg(t('no_history_data_available'));
      setIsInitialLoading(false);
    }

    // cleanup
    return () => {
      isMounted = false;
      abortAllRequests();
      if (maybeUpdateTimer !== null) {
        clearTimeout(maybeUpdateTimer);
      }
    };
  }, [series, timeRange, refreshIteration, isAutoRefreshOn, maxPhaseCurrent, t]);

  if (isInitialLoading) {
    return (
      <Loader text={t('loading_history_data')} />
    );
  }

  if (chartData) {
    const plotlyDataArray = chartData.phaseCurrents.map((serie: ChartData, index: number) => {
      return {
        type: 'scatter',
        x: serie.data.times.map((t) => (new Date(t))),
        y: serie.data.values,
        yhoverformat: '.2f',
        mode: 'lines',
        line: {
          shape: 'hv', // stairstep
          color: plotColors[index]
        },
        name: serie.name,
        connectgaps: false,
      };
    });
    if (chartData.surfaceLevel) {
      plotlyDataArray.push({
        type: 'scatter',
        x: chartData.surfaceLevel.data.times.map((t: string) => new Date(t)),
        y: chartData.surfaceLevel.data.values,
        yhoverformat: '.2f',
        mode: 'lines',
        name: t('surface_level'),
        yaxis: 'y2',
        connectgaps: false,
        line: {
          color: '#0093E5',
        }
      })
    }

    // Pick the phase current axis y maximum. Get a round value with some headroom.
    const phaseCurrentRangeMax = Math.max(2.0, 1.2 * maxPhaseCurrent);

    return (
      <Plot
        data={plotlyDataArray}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        removeNoticeMarker={removeNoticeMarker}
        noticeMarker={noticeMarker}
        showTimerangePicker
        layout={{
          legend: {
            y: -0.2
          },
          xaxis: {
            type: 'date',
            showgrid: false,
            color: 'white',
          },
          yaxis: {
            title: `${t('phase_current')} (A)`,
            range: [0.0, phaseCurrentRangeMax],
            color: 'white',
          },
          yaxis2: {
            title: `${t('surface_level')} (m)`,
            rangemode: 'tozero',
            color: 'white',
            overlaying: 'y',
            side: 'right',
            showgrid: false,
          },
        }}
      />
    )
  }
  // Error loading
  return (
    <Centered>
      <ErrorMsg message={errorMsg} />
    </Centered>
  );
}

const IntermediateChart = ({ series, type, title, timeRange, setTimeRange, unit }: IntermediateChartProps) => {
  const { t } = useTranslation();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [chartData, setChartData] = useState<any>(null);
  const [refreshIteration, setRefreshIteration] = useState<number>(0); // TODO: hack :)
  const [yMax, setYMax] = useState<number>(0);

  //Should automatic refreshing be enabled for chart?
  // Yes if the range is relative (e.g. last x// hours).
  const isAutoRefreshOn = timeRange[0].includes('now') || timeRange[1].includes('now');

  useEffect(() => {
    let isMounted = true;
    const aborter = new AbortController();
    const pendingRequests = new Map();
    let tmpChartData: ChartData[] = [];
    let maybeUpdateTimer: ReturnType<typeof setTimeout> | null = null;
    let yAxisMax: number = 0;
    
    const fetchSeries = (serie: Serie) => {
      // Save the aborter.
      pendingRequests.set(serie.publicId, aborter);
      const params = new URLSearchParams({
        from: requestedTimeFormat(timeRange[0]),
        to: requestedTimeFormat(timeRange[1]),
        iv: type
      }).toString()

      API.getIntermediateSeries(serie.publicId, params, aborter.signal).then((data: PlotData) => {
        pendingRequests.delete(serie.publicId);
        tmpChartData.push({...serie, data});
        const newMax = Math.max(...data.values);
        if (newMax > yAxisMax) {
          yAxisMax = Math.max(...data.values);
        }
        if (isMounted && pendingRequests.size === 0) {
          tmpChartData = tmpChartData.sort((a, b) => a.name.localeCompare(b.name));
          setChartData(tmpChartData);
          setYMax(yAxisMax);

          if (isAutoRefreshOn) {
            maybeUpdateTimer = setTimeout(() => {
              setRefreshIteration(refreshIteration + 1);
            }, 10000);
          }
          setIsInitialLoading(false);
        }
      }).catch(() => {
        if (isMounted) {
          setErrorMsg(t('error_data'));
          setIsInitialLoading(false);
          abortAllRequests();
        }
      })
    }

    const abortAllRequests = () => {
      for (const publicId in pendingRequests) {
        pendingRequests.get(publicId).abort();
      }
      pendingRequests.clear();
      aborter.abort();
    };
    if (series.length !== 0) {
      for (const serie of series) {
        fetchSeries(serie);
      }
    }
    
    // cleanup
    return () => {
      isMounted = false;
      abortAllRequests();
      if (maybeUpdateTimer !== null) {
        clearTimeout(maybeUpdateTimer);
      }
    };
  }, [series, timeRange, refreshIteration, isAutoRefreshOn, type, t]);

  if (isInitialLoading) {
    return (
      <Loader />
    );
  }

  if (chartData) {
    const plotlyDataArray = chartData.map((serie: ChartData, index: number) => {
      const averageValueStr: string = (serie.data.avg === undefined || serie.data.avg === null)
        ? '–' : `${serie.data.avg.toFixed(2)} ${unit}`;

      return {
        type: 'scatter',
        mode: 'lines+markers',
        x: serie.data.times.map(t => new Date(t)),
        y: serie.data.values,
        yhoverformat: '.2f',
        line: {
          shape: 'linear',
          color: plotColors[index]
        },
        name: `${serie.name} (${t('avg')}: ${averageValueStr})`,
        connectgaps: false,
      };
    });

    // Pick the phase current axis y maximum. Get a round value with some headroom.
    const yRangeMax = Math.max(2.0, 1.2 * yMax);

    return (
      <Plot
        data={plotlyDataArray}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        yAnnotation={unit}
        layout={{
          legend: {
            y: -0.2
          },
          xaxis: {
            type: 'date',
            showgrid: false,
            color: 'white',
          },
          yaxis: {
            range: [0.0, yRangeMax],
            color: 'white',
          },
          title: {
            text: title,
            yanchor: 'top',
          }
        }}
      />
    )
  }
  // Error loading
  return (
    <Centered>
      <ErrorMsg message={errorMsg} />
    </Centered>
  );
}

const IntermediateTable = ({ series, timeRange }: IntermediateTableProps) => {
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [runtimeData, setRuntimeData] = useState<Record<string, {runtimeSelectedRange: number, runtimeTotal: number}> | null>(null);
  const [cyclesData, setCyclesData] = useState<Record<string, {cyclesSelectedRange: number}> | null>(null);

  useEffect(() => {
    let isMounted = true;
    const aborter = new AbortController();
    const getParams = (iv: string) => {
      return new URLSearchParams({
        from: requestedTimeFormat(timeRange[0]),
        to: requestedTimeFormat(timeRange[1]),
        iv: iv
      }).toString()
    }

    setRuntimeData(null);
    setCyclesData(null);

    // Fetch data for the table. NOTE: the chart data is loaded separately at the moment.
    const runtimeRequests: Promise<{seriesId: string, runtimeSelectedRange: number, runtimeTotal: number}>[] = series.map((serie: Serie) => (
      API.getIntermediateSeries(serie.publicId, getParams('cumulative_on_time'), aborter.signal).then((data: PlotData) => {
        if (data.values.length) {
          const first = data.values[0];
          const latest = data.values[data.values.length-1];
          return { seriesId: serie.publicId, runtimeSelectedRange: latest - first, runtimeTotal: latest }
        } else {
          // NOTE: total runtime is set 0 if the query returned no data. Displaying of total runtime
          // is dependant on the selected range. Total runtime should be queried separately for it
          // to work every time.
          return { seriesId: serie.publicId, runtimeSelectedRange: 0, runtimeTotal: 0 }
        }

      })
    ))
    const cyclesRequests: Promise<{seriesId: string, cyclesSelectedRange: number}>[] = series.map((serie: Serie) => (
      API.getIntermediateSeries(serie.publicId, getParams('num_cycles'), aborter.signal).then((data: PlotData) => {
        const numCycles = data.values.reduce((acc: number, a: number | null) => acc + (a || 0), 0);
        return { seriesId: serie.publicId, cyclesSelectedRange: numCycles };
      })
    ))
    // Merge data from all the requests
    Promise.all([
      Promise.all(runtimeRequests).then(runtimeValues => {
        if (isMounted) {
          setRuntimeData(Object.fromEntries(runtimeValues.map(v => ([v.seriesId, v]))));
        }
      }), Promise.all(cyclesRequests).then(cyclesValues => {
        if (isMounted) {
          setCyclesData(Object.fromEntries(cyclesValues.map(v => ([v.seriesId, v]))));
        }
      }),
    ]).then(() => {
      if (isMounted) {
        setErrorMsg('');
      }
    })
    .catch(() => {
      if (isMounted) {
        setErrorMsg(t('error_data'));
      }
    });

    return () => {
      aborter.abort();
      isMounted = false;
    }
  }, [series, timeRange, t]);

  if (errorMsg) {
    return (
      <Centered>
        <ErrorMsg message={errorMsg} />
      </Centered>
    );
  }

  const loading = !runtimeData || !cyclesData;
  if (loading) {
    return <Loader/>;
  }

  return (
    <Table>
      <tbody>
        <tr>
          <th></th>
          {series.map(s => (<th key={s.publicId}>{s.name}</th>))}
        </tr>
        <tr>
          <td>{t('total_running_time')}</td>
          {series.map(s => (<td key={s.publicId}>{ convertSecondsToHMS(runtimeData[s.publicId].runtimeTotal) }</td>))}
        </tr>
        <tr>
          <td>{t('running_time_within_time_range')}</td>
          {series.map(s => (<td key={s.publicId}>{ convertSecondsToHMS(runtimeData[s.publicId].runtimeSelectedRange) }</td>))}
        </tr>
        <tr>
          <td>{t('cycles_within_time_range')}</td>
          {series.map(s => (<td key={s.publicId}>{ cyclesData[s.publicId].cyclesSelectedRange }</td>))}
        </tr>
      </tbody>
    </Table>
  );
}

const IntermediateValues = ({ series, timeRange, setTimeRange }: IntermediateValuesProps) => {
  const { t } = useTranslation();

  const chartConfig = [
    {
      type: 'cycle_on_time',
      title: t('pumping_cycle_length'),
      unit: 'min'
    },
    {
      type: 'cycle_on_median',
      title: t('operating_current'),
      unit: 'A'
    },
    {
      type: 'cycle_off_time',
      title: t('pumping_cycles_interval'),
      unit: 'h'
    },
    {
      type: 'duty_cycle',
      title: t('percentage_of_time_on'),
      unit: '%'
    },
  ]

  return (
    <>
      <h1>{t('intermediate_values')}</h1>
      { series.length > 0 ?
        <>
          <div className={styles.intermediateValuesLayout}>
            <SectionBox layoutArea={'table'}>
              <IntermediateTable series={series} timeRange={timeRange} />
            </SectionBox>

            { chartConfig.map(item => {
              return (
                <SectionBox key={item.title} layoutArea={item.type}>
                  <IntermediateChart
                    series={series}
                    timeRange={timeRange}
                    setTimeRange={setTimeRange}
                    type={item.type}
                    title={item.title}
                    unit={item.unit}
                  />
                </SectionBox>
              )
            }) }
          </div>
        </>
      :
        <SectionBox>
          <Centered>
            <ErrorMsg message={t('no_data_available')} />
          </Centered>
        </SectionBox>
      }
    </>
  );
}

const Address = ({fleet}: {fleet: ApiFleet}): JSX.Element => {
  const { t } = useTranslation();

  const columns: [keyof ApiFleet, string][] = [
    ['address', t('no_street_address')],
    ['postalCode', t('no_postal_code')],
    ['locality', t('no_locality')]
  ];
  const rows: JSX.Element[] = [];
  for (const [key, label] of columns) {
    const value = fleet[key] ? <p key={key}>{fleet[key]}</p> : <p key={key} style={{fontStyle: 'italic'}}>{label}</p>;
    rows.push(value);
  }
  return (
    <div className={styles.address}>
      {rows}
    </div>
  )
};

const Station = (): JSX.Element => {
  const { t } = useTranslation();
  const { alerts, loadingAlerts } = useAlerts();
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string | null>(null);
  const [stationRespData, setStationRespData] = useState<ApiStation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [timeRange, setTimeRange] = useState<string[]>(['now-24h', 'now']);
  const [noticeMarker, setNoticeMarker] = useState<INoticeMarker | null>(null);
  const [alertHistory, setAlertHistory] = useState<IAlert[] | null>(null);
  const stationId = params.publicId;

  useEffect(() => {
    let isMounted = true;
    if (stationId) {
      const abortRequest = new AbortController();
      API.getStationById(stationId, abortRequest.signal).then(data => {
        if (data.fleet.children.length > 0) {
          // This is not a leaf fleet.
          navigate('/fleet');
        }
        setStationRespData(data);
        const fleetNames = data.partOfFleets.map((item) => `${item.name} - `).join('');
        setTitle(fleetNames + data.fleet.name);
      }).catch(() => {
        navigate('/fleet');
      }).finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      })
      return () => {
        isMounted = false;
        abortRequest.abort();
      };
    }
  }, [stationId, navigate]);

  // Load alert history data for station.
  useEffect(() => {
    let isMounted = true;
    if (stationId) {
      const params = new URLSearchParams({fleet: stationId, page: '0', orderBy: '-severity,-startTime'});
      API.getAlerts(params.toString()).then(data => {
        if (isMounted) {
          const alerts: IAlert[] = data.alerts;
          setAlertHistory(alerts.slice(0, 5)); // Pick 5.
        }
      })
      return () => {
        isMounted = false;
      };
    }
  }, [stationId]);

  const addNoticeMarker = (notice: IAlert) => {
    const { timerangeStart, timerangeEnd, noticeTitle, noticeStart } = createNoticeMarker(notice);
    setTimeRange([timerangeStart, timerangeEnd]);
    setNoticeMarker({ title: noticeTitle, startTime: noticeStart });
  }

  const removeNoticeMarker = () => {
    setNoticeMarker(null);
  }

  const [alertStatus, mapMarker]: [Status, IMarker[]] = useMemo(() => {
    let status: Status = 'OK';
    const mapMarker: IMarker[] = [];
    if (alerts && !loadingAlerts && stationRespData) {
      const stationAlerts = alerts.filter(alert => alert.forFleetPublicId === stationId);
      status = mostSevereAlertStatus(stationAlerts);
      if (stationRespData.fleet.coordinates) {
        const status = alerts.findIndex(alert => alert.forFleetPublicId === stationRespData.fleet.publicId) > -1 ? 'DANGER' : 'OK';
        const partOfFleets = stationRespData.partOfFleets.map((item: any) => item.name)
        partOfFleets.push(stationRespData.fleet.name)
        mapMarker.push({
          coordinates: {lat: stationRespData.fleet.coordinates[0], lng: stationRespData.fleet.coordinates[1]},
          partOfFleets: partOfFleets,
          status: status,
          publicId: stationRespData.fleet.publicId,
          navigation: false
        })
      }
    }
    return [status, mapMarker];
  }, [stationId, alerts, loadingAlerts, stationRespData]);

  const [pumps, chartedSeries, phaseCurrentSeries, tankEquipment] = useMemo(() => {
    const pumps: Partial<IPump>[] = [];
    const chartedSeries: Serie[] = [];
    const phaseCurrentSeries: Serie[] = [];
    let tankEquipment: ApiEquipmentWithSeries | null = null;
    if (stationRespData !== null) {
      for (const eq of stationRespData.equipment) {
        if ('pump' in eq || 'motor' in eq) {
          pumps.push({
            publicId: eq.publicId,
            name: eq.name,
            modelName: (eq.pump && eq.pump.modelName) || (eq.motor && eq.motor.modelName) || undefined,
            nominalCurrent: (eq.motor && eq.motor.nominalCurrentAmperes) || undefined,
            nominalPower: (eq.motor && eq.motor.nominalPower) || (eq.pump && eq.pump.nominalPower) || undefined,
            motorFaultAlert: Boolean(eq.series.find((s) => s.hasAlertRules)),
          });
        } else {
          tankEquipment = eq;
        }
        for (const s of eq.series) {
          if (s.quantity === 'phase_current') {
            phaseCurrentSeries.push({
              name: eq.name,
              publicId: s.id,
            });
          }
          if (s.quantity === 'phase_current' || s.quantity === 'surface_level') {
            chartedSeries.push({
              name: eq.name,
              type: s.quantity,
              publicId: s.id,
            });
          }
        }
      }
    }
    return [pumps, chartedSeries, phaseCurrentSeries, tankEquipment];
  }, [stationRespData])

  if (loading || !stationRespData) {
    return (
      <Loader text={t('loading_station')} />
    );
  }

  const surfaceLevelSeries = tankEquipment?.series.find((s) => (s.quantity === 'surface_level')) || null;

  const breadcrumbs = formatStationPath(stationRespData ? [...stationRespData.partOfFleets, stationRespData.fleet]: []);

  return (
    <>
      { title !== null && <Title title={title} /> }
      <div style={{display: 'flex'}}>
        <Breadcrumbs leftArrowTo='/fleet' links={breadcrumbs} status={alertStatus} />
        { hasEditPermissionForOrg(stationRespData.fleet.organizationPublicId, user) &&
          <div style={{marginLeft: 'auto', padding: '0 0 0 10px'}}>
            <EditButton
              onClick={() => navigate(`/fleet/${stationId}/edit`, {state: { from: 'station' }})}
              data-tooltip-id='main-tooltip'
              data-tooltip-content={t('edit_station')}
              data-tooltip-place='left'
            />
          </div>
        }
      </div>
      <div className={styles.stationPageLayout}>
        <div className={styles.firstSection}>
          <SectionBox title={t('station_overview')} status={alertStatus}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <OverviewTable pumps={pumps} deviceInstallations={
                  stationRespData.deviceInstallations.map((di) => ({
                    serial: di.serial,
                    typeCode: di.typeCode,
                    installTime: new Date(di.installTime),
                    uninstallTime: di.uninstallTime !== null ? new Date(di.uninstallTime) : null,
                  }))
                } tankEquipment={tankEquipment} fleetInfo={stationRespData}
              />
              { hasEditPermissionForOrg(stationRespData.fleet.organizationPublicId, user) &&
                <div style={{margin: '10px 0 0 auto', display: 'flex', flexDirection: 'row'}}>
                  { !surfaceLevelSeries && (
                    <div style={{marginRight: pumps.length < 2 ? 20: 0}}>
                      <WaterDropButton onClick={() => navigate(`/station/${stationId}/new-surface-level`)} />
                    </div>
                  ) }
                  { pumps.length < 2 && 
                    <AddButton onClick={() => navigate(`/station/${stationId}/new-pump`)} />
                  }
                </div>
              }
            </div>
          </SectionBox>

          { alertHistory && (
            <SectionBox title={`${t('alerts')}`} noOverflow marginTop>
              { alertHistory.length === 0 ? (
                <p>{t('no_alerts')}</p>
              ) : (
                <>
                  <RecentAlerts
                    alertHistory={alertHistory}
                    showAlerting={alertStatus !== 'OK'}
                    onJumpToAlert={addNoticeMarker}
                  />
                  <div className={styles.allAlerts}>
                    <Link to={`/alerts?fleet=${stationId}`}>{ t('show_all_alerts') }</Link>
                  </div>
                </>
              ) }
            </SectionBox>
          )}

          <SectionBox title={t('location')} marginTop>
            { stationRespData.fleet.coordinates ?
              <LeafletMap
                markers={mapMarker}
              />
            :
              <Centered>
                <ErrorMsg message={t('no_coordinates')} />
              </Centered>
            }
            <Address fleet={stationRespData.fleet} />
          </SectionBox>
        </div>
        <div className={styles.secondSection}>
          <SectionBox title={t('history')} noOverflow>
            <StationChart
              series={chartedSeries}
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              noticeMarker={noticeMarker}
              removeNoticeMarker={removeNoticeMarker}
            />
          </SectionBox>
        </div>
      </div>

      <IntermediateValues
        series={phaseCurrentSeries}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
    </>
  );
}

export default Station;
