import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API, { ApiEquipmentDetails } from '../../api';
import { DeviceData, FormError } from "../../types";
import { AlertForm } from '../Forms';
import Breadcrumbs from "../Breadcrumbs";
import { PageLoader, Button, ErrorMsg } from '../Sugar';
import { useNotification } from "../../hooks";
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import SectionBox from '../SectionBox';
import Title from '../Title';
import { DeviceInputsTable } from '../Devices';
import { formatInputValue } from '../Devices/DeviceInputsTable';
import { AlertFormData } from '../Forms/AlertForm';
import { formatStationPath } from '../Station';
import * as yup from 'yup';
import { digitalInputAlertSchema } from '../Forms/Validations';


interface DeviceInstallation {
  serial: string,
  typeCode: string,
  installTime: string,
  uninstallTime: string | null,
}

interface PartOfFleet {
  publicId: string,
  name: string,
}

interface StationInfo {
  deviceInstallation: DeviceInstallation | null,
  fleetPath: PartOfFleet[],
}

const getDeviceInputNameForPump = (equipment: { name: string }): 'DI1' | 'DI2' | null => {
  // Assume that Pump 1 fault is connected to DI1, Pump 2 to DI2... etc.
  const nameComponents = equipment.name.split(' ');
  if (nameComponents.length !== 2) {
    return null;
  } else if (nameComponents[1] === '1') {
    return 'DI1';
  } else if (nameComponents[1] === '2') {
    return 'DI2';
  } else {
    return null;
  }
};

export const NewMotorAlert = () => {
  const { stationPublicId, equipmentPublicId } = useParams() as {stationPublicId: string, equipmentPublicId: string};
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const [title, setTitle] = useState<string>(t('motor_fault_alert'));
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [formData, setFormData] = useState<AlertFormData>({});
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [equipmentInfo, setEquipmentInfo] = useState<ApiEquipmentDetails | null>(null);
  const [savePending, setSavePending] = useState<boolean>(false);
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);
  const [inputInfo, setInputInfo] = useState<string>('');

  useEffect(() => {
    API.getEquipmentDetailsById(equipmentPublicId).then((data) => {
      setEquipmentInfo(data);
      const { equipment, partOfFleets, deviceInstallation } = data;
      if (!deviceInstallation || deviceInstallation.uninstallTime) {
        setErrorMsg(t('no_field_device'));
      }
      const fleetNames = partOfFleets.map((item) => `${item.name} - `).join(' ');
      setTitle(`${fleetNames} ${equipment.name} - ${t('motor_fault_alert')}`);

    }).catch((e) => {
      console.log(e);
      navigate('/fleet');
    })
  }, [t, navigate, setNotification, equipmentPublicId]);

  useEffect(() => {
    // Load latest input states for reference.
    if (equipmentInfo) {
      const deviceInputName = getDeviceInputNameForPump(equipmentInfo.equipment);
      if (deviceInputName && equipmentInfo.deviceInstallation) {
        const serial = equipmentInfo.deviceInstallation.serial;
        API.getDevice(serial).then((data: {
          inputs: {input: string, value: number | boolean | null, time?: string}[],
          type: string,
        }) => {
          const deviceData: DeviceData = {
            inputs: data.inputs.map((input) => ({
              ...input,
              time: input.time ? new Date(input.time) : null})
            ),
            type: data.type
          };
          const input = deviceData.inputs.find((item) => item.input === deviceInputName);
          if (input === undefined) {
            setErrorMsg(t('no_field_device_input'));
          } else {
            setInputInfo(`${input.input}: ${formatInputValue(input, deviceData.type)}`);
            setDeviceData(deviceData);
          }
        }).catch((e) => {
          console.log(e);
          navigate('/fleet');
        }).finally(() => {
          setLoading(false);
        });
      }
    }
  }, [equipmentInfo, navigate, t]);

  const handleSubmit = async (schema: yup.ObjectSchema<Record<string, any>>) => {
    if (!equipmentInfo || !equipmentInfo.deviceInstallation) {
      return;
    }
    try {
      schema.validateSync(formData, {abortEarly: false});
      // Create the alert.
      setSavePending(true);
      const alertInfo = {
        type: 'MOTOR_ALERT',
        stationPublicId,
        equipmentPublicId,
        normalState: formData.normalState,
        serial: equipmentInfo.deviceInstallation.serial,
        deviceInput: getDeviceInputNameForPump(equipmentInfo.equipment),
      };

      API.addNewAlert(alertInfo).then(() => {
        navigate(`/station/${stationPublicId}`);
        setNotification(t('alert_information_saved'), 'OK');
      }).catch(() => {
        setNotification(t('error_submit'), 'DANGER');
      }).finally(() => {
        setSavePending(false);
      });

    } catch (error: any) {
      if (!yup.ValidationError.isError(error)) {
        throw error;
      }
      const newErrors: FormError[] = [];
      error.inner.forEach((err) => {
        if (err.path) {
          newErrors.push({[err.path]: err.message});
        }
      });
      setFormErrors(newErrors);
    }
  };

  const handleFormChange = (inputName: string, value: string) => {
    setFormData({
      ...formData,
      [inputName]: value
    })
    const newErrors = formErrors.filter(err => !Object.keys(err).includes(inputName));
    setFormErrors(newErrors);
  };

  const breadcrumbs = equipmentInfo ? [
    ...formatStationPath(equipmentInfo.partOfFleets),
    { title: `${equipmentInfo.equipment.name} ${t('motor_fault_alert')}` },
  ] : null;

  return (
    <div className={styles.newAlert}>
      <Title title={title} />
      { loading && <PageLoader /> }
      { breadcrumbs && <Breadcrumbs leftArrowTo='/fleet' links={breadcrumbs} /> }
      <h1>{ t('configure_motor_fault_alert') }</h1>
      { errorMsg ? (<ErrorMsg message={errorMsg} />) : (
      <>
        { deviceData && <div className={styles.deviceInputsContainer}>
          <SectionBox noOverflow>
            <div style={{margin: 20}}>
              <DeviceInputsTable data={deviceData} filter={'digital'} />
            </div>
          </SectionBox>
        </div> }
        { !loading &&
          <AlertForm
            formData={formData}
            errors={formErrors}
            handleFormChange={(inputName, value) => handleFormChange(inputName, value)}
            title={t('alert_setting')}
            type={'motor'}
            inputInfo={inputInfo}
          />
        }
        <div className={styles.buttonsContainer}>
          <Button onClick={() => navigate(`/station/${stationPublicId}`)} text={t('cancel')} disabled={savePending} type={'DANGER'} />
          <Button onClick={() => handleSubmit(digitalInputAlertSchema)} text={t('save')} loading={savePending} />
        </div>
      </>)}
    </div>
  );
}

export const NewOverflowAlert = () => {
  const { stationPublicId } = useParams() as { stationPublicId: string };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const [title, setTitle] = useState<string>(t('overflow_alert'));
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [formData, setFormData] = useState<AlertFormData>({});
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [stationInfo, setStationInfo] = useState<StationInfo | null>(null);
  const [savePending, setSavePending] = useState<boolean>(false);
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);
  const [inputInfo, setInputInfo] = useState<string>('');

  useEffect(() => {
    let isMounted = true;
    if (stationPublicId) {
      const aborter = new AbortController();
      API.getStationById(stationPublicId, aborter.signal).then(data => {
        const deviceInstallations = data.deviceInstallations as DeviceInstallation[];
        // Just pick any device that is not marked uninstalled.
        const deviceInstallation = deviceInstallations.find((di) => !di.uninstallTime) || null;
        if (!deviceInstallation) {
          setErrorMsg(t('no_field_device'));
        }
        const fleetPath: PartOfFleet[] = [
          {name: data.fleet.name, publicId: data.fleet.publicId}
        ];
        setStationInfo({ deviceInstallation, fleetPath });
        const fleetNames = fleetPath.map((item) => `${item.name} - `).join(' ');
        setTitle(`${fleetNames} ${t('equipment_well')} - ${t('overflow_alert')}`);
      }).catch((e) => {
        console.log(e);
        navigate('/fleet');
      }).finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      })
      return () => {
        isMounted = false;
        aborter.abort();
      };
    }
  }, [stationPublicId, navigate, t]);

  useEffect(() => {
    // Load latest input states for reference.
    if (stationInfo) {
      const deviceInputName = 'DI3';
      if (stationInfo.deviceInstallation) {
        const serial = stationInfo.deviceInstallation.serial;
        API.getDevice(serial).then((data: {
          inputs: {input: string, value: number | boolean | null, time?: string}[],
          type: string,
        }) => {
          const deviceData: DeviceData = {
            inputs: data.inputs.map((input) => ({
              ...input,
              time: input.time ? new Date(input.time) : null})
            ),
            type: data.type
          };
          const input = deviceData.inputs.find((item) => item.input === deviceInputName);
          if (input === undefined) {
            setErrorMsg(t('no_field_device_input'));
          } else {
            setInputInfo(`${input.input}: ${formatInputValue(input, deviceData.type)}`);
            setDeviceData(deviceData);
          }
        }).catch((e) => {
          console.log(e);
          navigate('/fleet');
        }).finally(() => {
          setLoading(false);
        });
      }
    }
  }, [stationInfo, navigate, t]);

  const handleSubmit = async (schema: yup.ObjectSchema<Record<string, any>>) => {
    if (!stationInfo || !stationInfo.deviceInstallation) {
      return;
    }
    try {
      schema.validateSync(formData, {abortEarly: false});
      // Create the alert.
      setSavePending(true);
      const alertInfo = {
        type: 'OVERFLOW_ALERT',
        stationPublicId,
        normalState: formData.normalState,
        serial: stationInfo.deviceInstallation.serial,
        deviceInput: 'DI3',
      };

      API.addNewAlert(alertInfo).then(() => {
        navigate(`/station/${stationPublicId}`);
        setNotification(t('alert_information_saved'), 'OK');
      }).catch(() => {
        setNotification(t('error_submit'), 'DANGER');
      }).finally(() => {
        setSavePending(false);
      });

    } catch (error: any) {
      if (!yup.ValidationError.isError(error)) {
        throw error;
      }
      const newErrors: FormError[] = [];
      error.inner.forEach((err) => {
        if (err.path) {
          newErrors.push({[err.path]: err.message});
        }
      });
      setFormErrors(newErrors);
    }
  };

  const handleFormChange = (inputName: string, value: string) => {
    setFormData({
      ...formData,
      [inputName]: value
    })
    const newErrors = formErrors.filter(err => !Object.keys(err).includes(inputName));
    setFormErrors(newErrors);
  };

  const breadcrumbs = stationInfo ? [
    ...formatStationPath(stationInfo.fleetPath),
    { title: `${t('equipment_well')} - ${t('overflow_alert')}` },
  ] : null;

  return (
    <div className={styles.newAlert}>
      <Title title={title} />
      { loading && <PageLoader /> }
      { breadcrumbs && <Breadcrumbs leftArrowTo='/fleet' links={breadcrumbs} /> }
      <h1>{ t('configure_overflow_alert') }</h1>
      { errorMsg ? (<ErrorMsg message={errorMsg} />) : (
      <>
        { deviceData && <div className={styles.deviceInputsContainer}>
          <SectionBox noOverflow>
            <div style={{margin: 20}}>
              <DeviceInputsTable data={deviceData} filter={'digital'} />
            </div>
          </SectionBox>
        </div> }
        { !loading &&
          <AlertForm
            formData={formData}
            errors={formErrors}
            handleFormChange={(inputName, value) => handleFormChange(inputName, value)}
            title={t('alert_setting')}
            type={'overflow'}
            inputInfo={inputInfo}
          />
        }
        <div className={styles.buttonsContainer}>
          <Button onClick={() => navigate(`/station/${stationPublicId}`)} text={t('cancel')} disabled={savePending} type={'DANGER'} />
          <Button onClick={() => handleSubmit(digitalInputAlertSchema)} text={t('save')} loading={savePending} />
        </div>
      </>)}
    </div>
  );
}
