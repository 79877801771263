import { Annotations, PlotData } from "plotly.js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Plotly from "react-plotly.js"
import { INoticeMarker } from "../../types";
import { formatDateTime } from "../../util";
import TimerangePicker, { rangeSubtractionInMs, relativeRanges } from "../TimerangePicker";
import styles from './styles.module.css'

interface PlotProps {
  data: Plotly.Data[],
  layout: Partial<Plotly.Layout>,
  onRelayout?: (event: Readonly<Plotly.PlotRelayoutEvent>) => void,
  timeRange?: string[],
  setTimeRange?: (timeRange: string[]) => void,
  removeNoticeMarker?: () => void,
  noticeMarker?: INoticeMarker | null,
  showTimerangePicker?: boolean,
  xAnnotation?: string,
  yAnnotation?: string,
}

interface Legend {
  name: string,
  color: string
}

export const plotColors = ['#93C152', '#EFB600', '#0033FF', '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];
export const phaseCurrentColors = ['#93C152', '#EFB600', '#ff7f0e', '#2ca02c'];
export const analogInputColors = ['#1f77b4', '#0033FF', '#17becf', '#9467bd'];


const Plot = ({ data, layout, timeRange, setTimeRange, removeNoticeMarker, noticeMarker, showTimerangePicker, xAnnotation, yAnnotation }: PlotProps) => {
  const { t } = useTranslation();
  const [hiddenLegends, setHiddenLegends] = useState<number[]>([]);

  const handleRangeChanged = ([rangeStart, rangeEnd]: string[]) => {
    setTimeRange && setTimeRange([rangeStart, rangeEnd]);
    removeNoticeMarker && removeNoticeMarker();
  };

  const handleRelayout = (event: any) => {
    if ('xaxis.range[0]' in event) {
      // Format the picked range from plotly to a nice string "YYYY-mm-dd hh:mm:ss" in local time.
      const range = [event['xaxis.range[0]'], event['xaxis.range[1]']].map((t) => {
        const d = new Date(t);
        return formatDateTime(d);
      });
      setTimeRange && setTimeRange(range);
    }
  }

  const annotations: Partial<Annotations>[] = [];
  if (noticeMarker) {
    annotations.push({
      x: noticeMarker.startTime,
      y: 0,
      text: noticeMarker.title,
      yanchor: 'bottom',
      showarrow: true,
      arrowhead: 0,
      ax: 0,
      ay: -300,
      arrowcolor: 'red',
    });
  }
  if (xAnnotation) {
    annotations.push({
      xref: 'paper',
      yref: 'paper',
      x: 1,
      xanchor: 'left',
      y: 0,
      yanchor: 'top',
      text: xAnnotation,
      showarrow: false
    })
  }
  if (yAnnotation) {
    annotations.push({
      xref: 'paper',
      yref: 'paper',
      x: 0,
      xanchor: 'right',
      y: 1,
      yanchor: 'bottom',
      text: yAnnotation,
      showarrow: false
    })
  }

  const handleLegendClick = (index: number) => {
    let newHiddenLegends = [...hiddenLegends];
    if (newHiddenLegends.includes(index)) {
      newHiddenLegends = newHiddenLegends.filter(item => item !== index);
    } else {
      newHiddenLegends.push(index);
    }
    setHiddenLegends(newHiddenLegends);
    return true;
  }

  const getLegendColor = (item: PlotData) => {
    if (item.mode === 'lines' || item.mode === 'lines+markers') {
      return item.line.color;
    } else if (item.mode === 'markers') {
      if (item.marker.color === 'transparent' && item.marker.line) {
        return item.marker.line.color;
      } else {
        return item.marker.color;
      }
    }
  }

  if (data) {
    const legends: Legend[] = [];
    if (data.length > 0) {
      data.forEach(item => {
        legends.push({
          name: item.name as string,
          color: getLegendColor(item as PlotData) as string
        })
      })
    }

    if (hiddenLegends.length > 0) {
      data = data.map((item, i) => {
        if (hiddenLegends.includes(i)) {
          return {
            ...item,
            visible: 'legendonly',
          }
        }
        return item;
      })
    }

    return (
      <>
        { showTimerangePicker && timeRange && setTimeRange &&
          <div style={{display: 'flex', justifyContent: 'end'}}>
            <TimerangePicker name='history' onChange={handleRangeChanged} value={timeRange} />
          </div>
        }
        <div style={{position: 'relative'}}>
        <Plotly
          data={data}
          onRelayout={handleRelayout}
          layout={{
            ...layout,
            xaxis: timeRange ? {
              ...layout.xaxis,
              range: relativeRanges.includes(timeRange[0]) ?
                [new Date(new Date().getTime() - rangeSubtractionInMs[timeRange[0]]), new Date()]
              :
                [new Date(new Date(timeRange[0]).getTime() + 6e4), new Date(new Date(timeRange[1]).getTime() - 6e4)]
            }
            : layout.xaxis,
            annotations: annotations,
            autosize: true,
            showlegend: false,
            margin: {
              l: 40,
              r: 45,
              t: layout.title ? 50 : 20,
              pad: 5
            },
            hovermode: 'x unified',
            hoverlabel: {
              font: {
                color: 'black',
              },
              bgcolor: 'white'
            },
            font: {
              color: 'white',
            },
            paper_bgcolor: 'transparent',
            plot_bgcolor: 'transparent',
          }}
          config={{
            displaylogo: false,
            responsive: true,
          }}
          style={{width: '100%', height: 450}}
          
        />
        <div className={styles.legendsContainer}>
          { legends.map((item, i) => (
            <div key={i} onClick={() => handleLegendClick(i)} className={styles.item} style={{opacity: hiddenLegends.includes(i) ? 0.5 : 1}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: 30, height: 2, backgroundColor: item.color, marginRight: 5}}></div>
              </div>
              <div style={{fontFamily: 'Open Sans, verdana, arial, sans-serif', fontSize: 12}}>{item.name}</div>
            </div>
          )) }
        </div>
        </div>
      </>
    )
  } else {
    return <div>{t('no_info')}</div>
  }
}

export default Plot